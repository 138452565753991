import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import { colors } from "../../Utilities/index.js"

const VideoModel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorSecondary};
  z-index: 9999999999;
`

const VideoModal = ({ videoUrl }) => {
  return (
    <VideoModel>
      <div>
        {" "}
        <ReactPlayer
          className="video-wrapper"
          id="video-player"
          url={videoUrl}
          playing={true}
          controls={true}
          width={"100%"}
        />
      </div>
    </VideoModel>
  )
}

export default VideoModal
