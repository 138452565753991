import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  colors,
  headlineOne,
  bodyCopy,
  buttonOne,
  medWrapper,
} from "../Utilities"
import { TimelineMax, CSSPlugin, AttrPlugin } from "gsap/all"

// import ScrollMagic from "scrollmagic"
// import animationGsap from "animation.gsap"
// animationGsap
const plugins = [CSSPlugin, AttrPlugin]

const ImageBesideContentSection = styled.section`
  overflow: hidden;
  .wrapper {
    ${medWrapper};
    align-items: center;
    flex-direction: row-reverse;
  }

  .ibcContent {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50%);
      padding: 2rem 0 2rem 5rem;
    }

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }

    p {
      ${bodyCopy};

      &:last-of-type {
        margin: 0;
      }
    }

    a {
      ${buttonOne};
      padding-left: 0;
    }
  }

  .ibcImage {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50%);
      padding: 2rem;
    }
  }
`

class ImageBesideContent extends Component {
  constructor(props) {
    super(props)
    this.trigger = React.createRef()
    this.image = React.createRef()
    this.title = React.createRef()
    this.content = React.createRef()
    this.link = React.createRef()

    this.state = {}
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      import("scrollmagic").then(ScrollMagic => {
        import("animation.gsap").then(animationGsap => {
          const pluginsAgain = [animationGsap]
          this.state.controller = new ScrollMagic.Controller()
          this.state.timeline = new TimelineMax()
          this.state.timeline
            .add("start")
            .fromTo(
              this.image.current,
              1,
              { x: "-150px", autoAlpha: 0 },
              { x: "0px", autoAlpha: 1 },
              "start"
            )
            .staggerFromTo(
              [this.title.current, this.content.current, this.link.current],
              1,
              { x: "150px", autoAlpha: 0 },
              { x: "0px", autoAlpha: 1 },
              "0.25",
              "start"
            )
          let scene = new ScrollMagic.Scene({
            triggerElement: this.trigger.current,
            duration: 0,
            reverse: false,
            offset: 0,
            triggerHook: 0.5,
          })
            .setTween(this.state.timeline)
            .addTo(this.state.controller)
          //.addIndicators()
          this.setState({ scene })
        })
      })
    }
  }

  render() {
    const { imgFluid, imgAlt, title, content, btnText, btnLink } = this.props
    return (
      <ImageBesideContentSection ref={this.trigger}>
        <div className="wrapper">
          <div className="ibcContent">
            <div ref={this.title}>
              <h2>{title}</h2>
            </div>
            <div
              ref={this.content}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div ref={this.link}>
              <Link to={`/${btnLink}`}>{btnText}</Link>
            </div>
          </div>
          <div className="ibcImage" ref={this.image}>
            <Img fluid={imgFluid} alt={imgAlt} />
          </div>
        </div>
      </ImageBesideContentSection>
    )
  }
}

export default ImageBesideContent
