import React, { useState } from "react"
import styled from "styled-components"
import { headlineThree, colors, buttonTwo } from "../Utilities"
import Img from "gatsby-image"
import VideoModal from "./models/VideoModal"

const VideoCardStyled = styled.div`
  position: relative;
  padding: 3rem;
  min-height: 55rem;
  overflow: hidden;
  z-index: 100;

  @media (min-width: 1025px) {
    min-height: 45rem;
  }

  &:focus {
    outline: none;
  }

  .video-wrapper {
    iframe {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .video-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5rem;
    transition: all 0.15s ease;
    opacity: ${props => (props.videoActive ? "0" : "1")};
    background: ${props => (props.videoActive ? "transparent" : "#9DC9E4")};
    z-index: ${props => (props.videoActive ? "-1" : "1000")};

    .title-logo {
      width: 100%;
      max-width: 25rem;
      margin: auto;
    }

    h3 {
      ${headlineThree};
      width: 100%;
      color: ${colors.white};
      text-align: center;
    }

    button,
    a {
      ${buttonTwo};
    }
  }
`

const VideoCard = ({ video }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  return (
    <VideoCardStyled videoActive={videoPlaying}>
      <div className="video-title">
        <div className="title-logo">
          <Img
            fluid={video.image.localFile.childImageSharp.fluid}
            alt={video.title}
          />
        </div>
        <h3>{video.title}</h3>
        <button
          onClick={e => {
            setVideoPlaying(() => !videoPlaying)
          }}
        >
          Play Video
        </button>
      </div>
      {videoPlaying && <VideoModal videoUrl={video.video} />}
    </VideoCardStyled>
  )
}

export default VideoCard
