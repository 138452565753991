import React, { Component } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { standardWrapper, buttonOne, headlineThree, colors } from "../Utilities"
import { Link } from "gatsby"
import {
  TimelineMax,
  TweenMax,
  RoughEase,
  CSSPlugin,
  Elastic,
  Power0,
  AttrPlugin,
} from "gsap/all"
// import ScrollMagic from "scrollmagic"
// import animationGsap from "animation.gsap"
// import debugAddIndicators from "debug.addIndicators"

import TextureWood from "../elements/TextureWood"
import TextureCopper from "../elements/TextureCopper"
import TextureMarble from "../elements/TextureMarble"
// animationGsap

const plugins = [CSSPlugin, AttrPlugin]

const MESection = styled.div`
  .wrapper {
    ${standardWrapper};
    position: relative;
    padding-top: 0;
  }

  .consult-btn {
    width: 100%;
  }
`

const ImageOne = styled.div`
  width: calc(100%);

  @media (min-width: 768px) {
    width: calc(70% - 15rem);
    margin-right: 7.5rem;
    margin-left: 7.5rem;
  }
`

const ImageTwo = styled.div`
  width: calc(100%);
  margin-top: 10rem;

  @media (min-width: 768px) {
    position: relative;
    width: calc(30%);
    margin-top: 12.5rem;
  }

  .image-two-background {
    display: none;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      top: -5rem;
      right: -5rem;
      width: 100%;
      height: 30rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.46);
      z-index: -1;
    }
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  padding-left: 5rem;

  a {
    ${buttonOne};
  }
`

const SideWaysText = styled.div`
  position: absolute;
  top: calc(54% - 2.5rem);
  left: 2rem;
  right: 2rem;
  transform: translateY(-50%);
  transform-origin: left center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  z-index: 5;

  @media (min-width: 375px) {
    top: calc(49% - 2.5rem);
  }

  @media (min-width: 500px) {
    top: calc(47% - 2.5rem);
  }

  @media (min-width: 600px) {
    top: calc(46% - 2.5rem);
  }

  @media (min-width: 768px) {
    top: 80%;
    right: auto;
    left: 0;
    width: auto;
    transform: rotate(-90deg);
    z-index: -1;
  }

  .sidewaysText {
    position: relative;
    width: 100%;
    margin-bottom: 4rem;
    text-align: center;
    z-index: -1;

    @media (min-width: 768px) {
      width: auto;
      text-align: left;
    }

    &::after {
      display: none;
      width: calc(100%);
      height: 0.1rem;
      background: ${colors.colorSecondary};
      content: "";
    }

    @media (min-width: 768px) {
      &::after {
        display: block;
        width: calc(100% + 1rem);
        margin-left: -1rem;
      }
    }

    p {
      ${headlineThree};
      display: none;
      width: 100%;
      margin-bottom: 1rem;
      padding-left: 0;
      text-align: center;

      @media (min-width: 768px) {
        display: block;
        padding-left: 4rem;
        text-align: left;
      }
    }
  }
  .textures {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__container {
      position: relative;
      margin: 0 0.5rem;
      border-radius: 50%;
      overflow: hidden;
    }

    &__wood {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      overflow: hidden;
      .gatsby-image-wrapper {
        border-radius: 50% !important;
        overflow: hidden !important;
        &::after,
        &::before {
          border-radius: 50% !important;
          overflow: hidden !important;
        }
      }
    }

    &__marble {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 50%;
      overflow: hidden;
      .gatsby-image-wrapper {
        border-radius: 50% !important;
        overflow: hidden !important;
        &::after,
        &::before {
          border-radius: 50% !important;
          overflow: hidden !important;
        }
      }
    }

    &__copper {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      overflow: hidden;
      .gatsby-image-wrapper {
        border-radius: 50% !important;
        overflow: hidden !important;
        &::after,
        &::before {
          border-radius: 50% !important;
          overflow: hidden !important;
        }
      }
    }
  }
`

class MemoryExperience extends Component {
  constructor(props) {
    super(props)
    this.trigger = React.createRef()
    this.imageOne = React.createRef()
    this.imageTwo = React.createRef()
    this.background = React.createRef()
    this.textures = React.createRef()
    this.textureOne = React.createRef()
    this.textureTwo = React.createRef()
    this.textureThree = React.createRef()
    this.state = {}
  }

  componentWillUnmount() {
    this.state.timeLine.kill()
    this.state.controller.destroy()
    this.state.controller = null
    this.state.scene.destroy()
    this.state.scene = null
    this.state.timeLine2.kill()
    this.state.controller2.destroy()
    this.state.controller2 = null
    this.state.scene2.destroy()
    this.state.scene2 = null
    this.state.timeLine3.kill()
    this.state.controller3.destroy()
    this.state.controller3 = null
    this.state.scene3.destroy()
    this.state.scene3 = null
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      import("scrollmagic").then(ScrollMagic => {
        import("animation.gsap").then(animationGsap => {
          const pluginsAgain = [animationGsap]
          import("debug.addIndicators").then(debugAddIndicators => {
            this.state.controller = new ScrollMagic.Controller()
            this.state.controller2 = new ScrollMagic.Controller()
            this.state.controller3 = new ScrollMagic.Controller()
            this.state.timeLine = new TimelineMax()
            this.state.timeLine2 = new TimelineMax()
            this.state.timeLine3 = new TimelineMax()
            this.state.timeLine
              .add("start")
              .fromTo(
                this.imageOne.current,
                1,
                { y: "50px", autoAlpha: 0 },
                { y: "0px", autoAlpha: 1 },
                "start"
              )
            let scene = new ScrollMagic.Scene({
              triggerElement: this.trigger.current,
              duration: 0,
              reverse: false,
              offset: 0,
              triggerHook: 0.5,
            })
              .setTween(this.state.timeLine)
              .addTo(this.state.controller)
            // .addIndicators()
            this.state.timeLine2
              .fromTo(
                this.imageTwo.current,
                1,
                { y: "7.5%", ease: Power0.easeNone },
                { y: "-7.5%", ease: Power0.easeNone },
                "start"
              )
              .fromTo(
                this.background.current,
                1,
                { y: "-5%", ease: Power0.easeNone },
                { y: "5%", ease: Power0.easeNone },
                "start"
              )
            let scene2 = new ScrollMagic.Scene({
              triggerElement: this.trigger.current,
              duration: "150%",
              reverse: true,
              offset: 0,
              triggerHook: 1,
            })
              .setTween(this.state.timeLine2)
              .addTo(this.state.controller2)
            // .addIndicators()
            this.state.timeLine3.staggerFromTo(
              [
                this.textureThree.current,
                this.textureTwo.current,
                this.textureOne.current,
              ],
              0.5,
              { autoAlpha: 0, scale: 0, transformOrigin: "center center" },
              { autoAlpha: 1, scale: 1, transformOrigin: "center center" },
              "0.25"
            )
            let scene3 = new ScrollMagic.Scene({
              triggerElement: this.trigger.current,
              duration: 0,
              reverse: false,
              offset: 0,
              triggerHook: 0.5,
            })
              .setTween(this.state.timeLine3)
              .addTo(this.state.controller3)
            // .addIndicators()
            this.setState({ scene, scene2, scene3 })
          })
        })
      })
    }

    // this.state.controller = new ScrollMagic.Controller()
    // this.state.controller2 = new ScrollMagic.Controller()
    // this.state.controller3 = new ScrollMagic.Controller()
    // this.state.timeLine = new TimelineMax()
    // this.state.timeLine2 = new TimelineMax()
    // this.state.timeLine3 = new TimelineMax()
    // this.state.timeLine
    //   .add("start")
    //   .fromTo(
    //     this.imageOne.current,
    //     1,
    //     { y: "50px", autoAlpha: 0 },
    //     { y: "0px", autoAlpha: 1 },
    //     "start"
    //   )
    // let scene = new ScrollMagic.Scene({
    //   triggerElement: this.trigger.current,
    //   duration: 0,
    //   reverse: false,
    //   offset: 0,
    //   triggerHook: 0.5,
    // })
    //   .setTween(this.state.timeLine)
    //   .addTo(this.state.controller)
    // // .addIndicators()
    // this.state.timeLine2
    //   .fromTo(
    //     this.imageTwo.current,
    //     1,
    //     { y: "7.5%", ease: Power0.easeNone },
    //     { y: "-7.5%", ease: Power0.easeNone },
    //     "start"
    //   )
    //   .fromTo(
    //     this.background.current,
    //     1,
    //     { y: "-5%", ease: Power0.easeNone },
    //     { y: "5%", ease: Power0.easeNone },
    //     "start"
    //   )
    // let scene2 = new ScrollMagic.Scene({
    //   triggerElement: this.trigger.current,
    //   duration: "150%",
    //   reverse: true,
    //   offset: 0,
    //   triggerHook: 1,
    // })
    //   .setTween(this.state.timeLine2)
    //   .addTo(this.state.controller2)
    // // .addIndicators()
    // this.state.timeLine3.staggerFromTo(
    //   [
    //     this.textureThree.current,
    //     this.textureTwo.current,
    //     this.textureOne.current,
    //   ],
    //   0.5,
    //   { autoAlpha: 0, scale: 0, transformOrigin: "center center" },
    //   { autoAlpha: 1, scale: 1, transformOrigin: "center center" },
    //   "0.25"
    // )
    // let scene3 = new ScrollMagic.Scene({
    //   triggerElement: this.trigger.current,
    //   duration: 0,
    //   reverse: false,
    //   offset: 0,
    //   triggerHook: 0.5,
    // })
    //   .setTween(this.state.timeLine3)
    //   .addTo(this.state.controller3)
    // // .addIndicators()
    // this.setState({ scene, scene2, scene3 })
  }

  render() {
    const {
      imgOneFluid,
      imgOneAlt,
      imgTwoFluid,
      imgTwoAlt,
      btnReq,
    } = this.props
    return (
      <MESection>
        <div className="wrapper" ref={this.trigger}>
          {btnReq === "yes" ? (
            <ButtonContainer>
              <Link to="/contact">Request a consult today</Link>
            </ButtonContainer>
          ) : (
            false
          )}
          <ImageOne id="imageOne" ref={this.imageOne}>
            <Img fluid={imgOneFluid} alt={imgOneAlt} />
          </ImageOne>
          <ImageTwo id="imageTwo">
            <div ref={this.imageTwo}>
              <Img fluid={imgTwoFluid} alt={imgTwoAlt} />
            </div>
            <div className="image-two-background" ref={this.background}>
              <TextureWood />
            </div>
          </ImageTwo>
          <SideWaysText>
            <div ref={this.textures} className="textures">
              <div
                ref={this.textureOne}
                className="textures__copper textures__container"
              >
                <TextureCopper />
              </div>
              <div
                ref={this.textureTwo}
                className="textures__marble textures__container"
              >
                <TextureMarble />
              </div>
              <div
                ref={this.textureThree}
                className="textures__wood textures__container"
              >
                <TextureWood />
              </div>
            </div>
            <div className="sidewaysText">
              <p>A Memory. An experience.</p>
            </div>
          </SideWaysText>
        </div>
      </MESection>
    )
  }
}

export default MemoryExperience
