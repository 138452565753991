import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  colors,
  headlineOne,
  bodyCopy,
  buttonOne,
  medWrapper,
} from "../Utilities"
import { TimelineMax, CSSPlugin, AttrPlugin } from "gsap/all"
import TextureCopper from "../elements/TextureCopper"
import TextureMarble from "../elements/TextureMarble"

// import ScrollMagic from "scrollmagic"
// import animationGsap from "animation.gsap"
// animationGsap
const plugins = [CSSPlugin, AttrPlugin]

const FourSectionsStyled = styled.section`
  padding-top: 0rem;
  overflow: hidden;
  @media (min-width: 768px) {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .wrapper {
    ${medWrapper};
    position: relative;
  }

  .topSec {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }

    @media (min-width: 900px) {
      margin-bottom: 15rem;
    }

    @media (min-width: 1025px) {
      margin-bottom: 5rem;
    }

    @media (min-width: 1100px) {
      margin-bottom: -4rem;
    }

    &__content {
      align-self: flex-start;
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(50% - 10rem);
        margin-left: 10rem;
      }

      h2 {
        ${headlineOne};
        color: ${colors.colorPrimary};
      }

      p {
        ${bodyCopy};

        &:last-of-type {
          margin: 0;
        }
      }

      a {
        ${buttonOne};
        padding-left: 0;
      }
    }

    &__image {
      position: relative;
      width: calc(100%);

      @media (min-width: 768px) {
        width: calc(50%);
      }

      &--wrapper {
        margin-left: auto;
      }

      &--background {
        display: none;

        @media (min-width: 768px) {
          display: block;
          position: absolute;
          top: -12.5rem;
          left: -5rem;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
  }

  .centreSec {
    width: 22.5rem;
    height: 22.5rem;
    border-radius: 50%;
    border: 2rem solid ${colors.white};
    overflow: hidden;

    @media (min-width: 375px) {
      width: 25rem;
      height: 25rem;
    }

    @media (min-width: 500px) {
      width: 30rem;
      height: 30rem;
    }

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
    @media (min-width: 768px) {
      display: none;
    }

    @media (min-width: 900px) {
      display: block;
      margin-bottom: 15rem;
    }
  }

  .botSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      margin-top: -5rem;
    }

    &__content {
      width: 100%;

      @media (min-width: 768px) {
        align-self: flex-end;
        width: calc(50% - 10rem);
        margin-right: 10rem;
      }

      h2 {
        ${headlineOne};
        color: ${colors.colorPrimary};
      }

      p {
        ${bodyCopy};

        &:last-of-type {
          margin: 0;
        }
      }

      a {
        ${buttonOne};
        padding-left: 0;
      }
    }

    &__image {
      position: relative;
      width: calc(100%);
      @media (min-width: 768px) {
        width: calc(50%);
      }

      &--wrapper {
      }

      &--background {
        display: none;

        @media (min-width: 768px) {
          display: block;
          position: absolute;
          bottom: -12.5rem;
          right: -5rem;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
  }
`

class FourSections extends Component {
  constructor(props) {
    super(props)
    this.trigger = React.createRef()
    this.imageTopLeft = React.createRef()
    this.topTitle = React.createRef()
    this.topContent = React.createRef()
    this.topLink = React.createRef()

    this.imageCenter = React.createRef()

    this.imageBotRight = React.createRef()
    this.botTitle = React.createRef()
    this.botContent = React.createRef()
    this.botLink = React.createRef()

    this.state = {}
  }

  animateTopSection = ScrollMagic => {
    this.state.topController = new ScrollMagic.Controller()
    this.state.topTL = new TimelineMax()

    this.state.topTL
      .add("start")
      .fromTo(
        this.imageTopLeft.current,
        1,
        { x: "-150px", autoAlpha: 0 },
        { x: "0px", autoAlpha: 1 },
        "start"
      )
      .staggerFromTo(
        [this.topTitle.current, this.topContent.current, this.topLink.current],
        1,
        { y: "150px", autoAlpha: 0 },
        { y: "0px", autoAlpha: 1 },
        "0.5",
        "start+=0.5"
      )

    let topScene = new ScrollMagic.Scene({
      triggerElement: this.trigger.current,
      duration: 0,
      reverse: false,
      offset: 0,
      triggerHook: 0.5,
    })
      .setTween(this.state.topTL)
      .addTo(this.state.topController)
    // .addIndicators()

    this.setState({ topScene })
  }

  animateBotSection = ScrollMagic => {
    this.state.botController = new ScrollMagic.Controller()
    this.state.botTL = new TimelineMax()

    this.state.botTL
      .add("start")
      .fromTo(
        this.imageBotRight.current,
        1,
        { x: "150px", autoAlpha: 0 },
        { x: "0px", autoAlpha: 1 },
        "start"
      )
      .staggerFromTo(
        [this.botTitle.current, this.botContent.current, this.botLink.current],
        1,
        { y: "150px", autoAlpha: 0 },
        { y: "0px", autoAlpha: 1 },
        "0.5",
        "start+=0.5"
      )

    let botScene = new ScrollMagic.Scene({
      triggerElement: this.trigger.current,
      duration: 0,
      reverse: false,
      offset: 650,
      triggerHook: 0.5,
    })
      .setTween(this.state.botTL)
      .addTo(this.state.botController)

    this.setState({ botScene })
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      import("scrollmagic").then(ScrollMagic => {
        import("animation.gsap").then(animationGsap => {
          const pluginsAgain = [animationGsap]
          this.animateTopSection(ScrollMagic)
          this.animateBotSection(ScrollMagic)
        })
      })
    }
  }

  render() {
    const { top, centre, bot } = this.props
    return (
      <FourSectionsStyled ref={this.trigger}>
        <div className="wrapper">
          <div className="topSec">
            <div className="topSec__content">
              <div ref={this.topTitle}>
                <h2>{top.fsTopTitle}</h2>
              </div>
              <div
                ref={this.topContent}
                dangerouslySetInnerHTML={{ __html: top.fsTopContent }}
              />
              <div ref={this.topLink}>
                <Link to={`/${top.fsTopBtnLink}`}>{top.fsTopBtnText}</Link>
              </div>
            </div>
            <div className="topSec__image">
              <div className="topSec__image--wrapper" ref={this.imageTopLeft}>
                <Img fluid={top.fsImgTopFluid} alt={top.fsImgTopAlt} />
              </div>

              <div className="topSec__image--background">
                <TextureCopper />
              </div>
            </div>
          </div>

          <div className="centreSec">
            <div ref={this.imageCenter}>
              <Img
                fluid={centre.fsImgCentreFluid}
                alt={centre.fsImgCentreAlt}
              />
            </div>
          </div>

          <div className="botSec">
            <div className="botSec__content">
              <div ref={this.botTitle}>
                <h2>{bot.fsBotTitle}</h2>
              </div>
              <div
                ref={this.botContent}
                dangerouslySetInnerHTML={{ __html: bot.fsBotContent }}
              />
              <div ref={this.botLink}>
                <Link to={`/${bot.fsBotBtnLink}`}>{bot.fsBotBtnText}</Link>
              </div>
            </div>
            <div className="botSec__image">
              <div className="botSec__image--wrapper" ref={this.imageBotRight}>
                <Img fluid={bot.fsImgBotFluid} alt={bot.fsImgBotAlt} />
              </div>
              <div className="botSec__image--background">
                <TextureMarble />
              </div>
            </div>
          </div>
        </div>
      </FourSectionsStyled>
    )
  }
}

export default FourSections
