import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { headlineOne, colors, fonts, buttonOne } from "../Utilities"
import { medWrapper } from "../Utilities"
import VideoCard from "./VideoCard"

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  draggable: true,
  infinite: true,
  speed: 300,
  centerMode: true,
  autoplaySpeed: 10000,
  centerMode: true,
  centerPadding: "100px",
  arrows: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
  ],
}

const VTSection = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: hidden;

  @media (min-width: 768px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .wrapper {
    ${medWrapper};
  }

  .vtTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      margin-bottom: 0;
      color: ${colors.colorPrimary};
    }
  }

  .vtLink {
    width: 100%;
    text-align: center;

    a {
      ${buttonOne};
      color: ${colors.colorPrimary};
    }
  }

  .test-slider {
    position: relative;

    @media (min-width: 768px) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10rem;
        background: ${colors.white};
        content: "";
        z-index: 500;
      }
    }

    .slick-arrow {
      display: none;

      @media (min-width: 768px) {
        display: block;
        position: absolute;
        left: 5rem;
        z-index: 501;
      }

      &::before {
        font-family: ${fonts.fontAwesome};
        font-weight: 100;
        color: ${colors.colorSecondary};
      }
    }
    .slick-prev {
      top: 40%;
      &::before {
        content: "\f054";
      }
    }

    .slick-next {
      top: 60%;
      &::before {
        content: "\f053";
      }
    }

    .slick-track {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .slick-slide {
        padding: 3rem;
        height: 100%;
        min-height: 55rem;

        @media (min-width: 1025px) {
          min-height: 45rem;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`

const VideoTestimonals = ({ data: { title, btnText, btnLink, videos } }) => {
  return (
    <VTSection id="videoTestimonials">
      <div className="wrapper">
        <div className="vtTitle">
          <h2>{title}</h2>
        </div>
        <div className="vtLink">
          <a target="_blank" rel="noopener noreferrer" href={btnLink}>
            {btnText}
          </a>
        </div>
      </div>
      <div>
        <Slider className="test-slider" {...settings}>
          {videos.map((video, index) => {
            return <VideoCard video={video} key={index} />
          })}
        </Slider>
      </div>
    </VTSection>
  )
}

export default VideoTestimonals
