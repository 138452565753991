import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import MemoryExperience from "../components/MemoryExperience"
import FourSections from "../components/FourSections"
import ImageBesideContent from "../components/ImageBesideContent"
import CalloutSection from "../components/CalloutSection"
import VideoTestimonals from "../components/VideoTestimonals"

const HomeWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

const IndexPage = props => {
  const location = props.location.pathname
  const { acf } = props.data.page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid

  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const meImgOneFluid = acf._ikag_me_image_one.localFile.childImageSharp.fluid
  const meImgOneAlt = acf._ikag_me_image_one.alt_text
  const meImgTwoFluid = acf._ikag_me_image_two.localFile.childImageSharp.fluid
  const meImgTwoAlt = acf._ikag_me_image_two.alt_text
  const meBtnReq = acf._ikag_me_req_con_btn

  const fsImgTopFluid =
    acf._ikag_fs_image_top_left.localFile.childImageSharp.fluid
  const fsImgTopAlt = acf._ikag_fs_image_top_left.alt_text
  const fsTopTitle = acf._ikag_fs_title_top
  const fsTopContent = acf._ikag_fs_content_top
  const fsTopBtnText = acf._ikag_fs_link_text_top
  const fsTopBtnLink = acf._ikag_fs_page_link_top.post_name

  const fsImgCentreFluid =
    acf._ikag_fs_centre_circle_image.localFile.childImageSharp.fluid
  const fsImgCentreAlt = acf._ikag_fs_centre_circle_image.alt_text

  const fsImgBotFluid =
    acf._ikag_fs_image_bottom_right.localFile.childImageSharp.fluid
  const fsImgBotAlt = acf._ikag_fs_image_bottom_right.alt_text
  const fsBotTitle = acf._ikag_fs_title_bottom
  const fsBotContent = acf._ikag_fs_content_bottom
  const fsBotBtnText = acf._ikag_fs_link_text_bottom
  const fsBotBtnLink = acf._ikag_fs_page_link_bottom.post_name

  const ibcImgFluid = acf._ikag_ibc_image.localFile.childImageSharp.fluid
  const ibcImgAlt = acf._ikag_ibc_image.alt_text
  const ibcTitle = acf._ikag_ibc_title
  const ibcContent = acf._ikag_ibc_content
  const ibcLinkText = acf._ikag_ibc_link_text
  const ibcLink = acf._ikag_ibc_link.post_name

  const cosTitleTop = acf._ikag_cos_title_top
  const cosTitleBot = acf._ikag_cos_title_italic
  const cosBtnText = acf._ikag_cos_button_text
  const cosBtnLink = acf._ikag_cos_button_link.post_name

  const tvTitle = acf._ikag_vt_title
  const tvBtnText = acf._ikag_vt_button_text
  const tvBtnLink = acf._ikag_vt_button_link
  const tvVideos = acf._ikag_vt_youtube_videos

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HomeWrapper>
        <HeroImage fluid={heroImgFluid} />
        <Intro
          title={introTitle}
          italic={introItalics}
          content={introContent}
        />
        <MemoryExperience
          imgOneFluid={meImgOneFluid}
          imgOneAlt={meImgOneAlt}
          imgTwoFluid={meImgTwoFluid}
          imgTwoAlt={meImgTwoAlt}
          btnReq={meBtnReq}
        />
        <FourSections
          top={{
            fsImgTopFluid,
            fsImgTopAlt,
            fsTopTitle,
            fsTopContent,
            fsTopBtnText,
            fsTopBtnLink,
          }}
          centre={{ fsImgCentreFluid, fsImgCentreAlt }}
          bot={{
            fsImgBotFluid,
            fsImgBotAlt,
            fsBotTitle,
            fsBotContent,
            fsBotBtnText,
            fsBotBtnLink,
          }}
        />
        <ImageBesideContent
          imgFluid={ibcImgFluid}
          imgAlt={ibcImgAlt}
          title={ibcTitle}
          content={ibcContent}
          btnText={ibcLinkText}
          btnLink={ibcLink}
        />
        <CalloutSection
          titleTop={cosTitleTop}
          titleBot={cosTitleBot}
          btnText={cosBtnText}
          btnLink={cosBtnLink}
        />
        <VideoTestimonals
          data={{
            title: tvTitle,
            btnText: tvBtnText,
            btnLink: tvBtnLink,
            videos: tvVideos,
          }}
        />
      </HomeWrapper>
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    page: wordpressPage(slug: { eq: "home" }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }

        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content

        _ikag_me_image_one {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_me_image_two {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_me_req_con_btn

        _ikag_fs_image_top_left {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_fs_title_top
        _ikag_fs_content_top
        _ikag_fs_link_text_top
        _ikag_fs_page_link_top {
          post_name
        }
        _ikag_fs_centre_circle_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_fs_image_bottom_right {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_fs_title_bottom
        _ikag_fs_content_bottom
        _ikag_fs_link_text_bottom
        _ikag_fs_page_link_bottom {
          post_name
        }

        _ikag_ibc_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_ibc_title
        _ikag_ibc_content
        _ikag_ibc_link_text
        _ikag_ibc_link {
          post_name
        }

        _ikag_cos_title_top
        _ikag_cos_title_italic
        _ikag_cos_button_text
        _ikag_cos_button_link {
          post_name
        }

        _ikag_vt_title
        _ikag_vt_button_text
        _ikag_vt_button_link
        _ikag_vt_youtube_videos {
          title
          video
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
